import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { UIKitProvider, LongTextWithTooltip, LongTextWithCopyAction } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "longtext"
    }}>{`LongText`}</h1>
    <h2 {...{
      "id": "longtextwithtooltip"
    }}>{`LongTextWithTooltip`}</h2>
    <Props of={LongTextWithTooltip} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
    <LongTextWithTooltip value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." mdxType="LongTextWithTooltip" />
    </UIKitProvider>
    <h2 {...{
      "id": "longtextwithcopyaction"
    }}>{`LongTextWithCopyAction`}</h2>
    <Props of={LongTextWithCopyAction} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
    <LongTextWithCopyAction value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." mdxType="LongTextWithCopyAction" />
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      